export const onOffToggleB2c = () => {
  var toggleBtn = document.getElementById('roundbutton-b2c')
  var toggleSwitch = document.getElementById('toggleswitch-b2c')
  var togglePaypalBtn = document.getElementById('roundbutton-paypal-capable')
  var togglePaypalSwitch = document.getElementById('toggleswitch-paypal-capable')
  var toggleAgeRestrictedGoodsBtn = document.getElementById('roundbutton-age-restricted-goods')
  var toggleAgeRestrictedGoodsSwitch = document.getElementById('toggleswitch-age-restricted-goods')

  if ((toggleBtn) && (toggleSwitch)) {
    toggleBtn.addEventListener('click', () => {
      if (toggleSwitch.checked) {
        toggleSwitch.checked = false;
      }
      else {
        toggleSwitch.checked = true;
      }
    });
  };

  if ((togglePaypalBtn) && (togglePaypalSwitch)) {
    togglePaypalBtn.addEventListener('click', () => {
      if (togglePaypalSwitch.checked) {
        togglePaypalSwitch.checked = false;
      }
      else {
        togglePaypalSwitch.checked = true;
      }
    });
  };

  if ((toggleAgeRestrictedGoodsBtn) && (toggleAgeRestrictedGoodsSwitch)) {
    toggleAgeRestrictedGoodsBtn.addEventListener('click', () => {
      if (toggleAgeRestrictedGoodsSwitch.checked) {
        toggleAgeRestrictedGoodsSwitch.checked = false;
      }
      else {
        toggleAgeRestrictedGoodsSwitch.checked = true;
      }
    });
  };
};


export const onOffToggleB2b = () => {
  var toggleBtnB2b = document.getElementById('roundbutton-b2b')
  var toggleBtnLimit = document.getElementById('roundbutton-limit')
  var toggleBtnDirectPayments = document.getElementById('roundbutton-direct-payments')
  var toggleBtnNotes = document.getElementById('roundbutton-notes')
  var toggleBtnInvoicing = document.getElementById('roundbutton-automated-invoicing')
  var toggleBtnSendConfirmationCopy = document.getElementById('roundbutton-send-b2b-oc-copy')
  var toggleBtnMov = document.getElementById('roundbutton-mov')
  var toggleBtnMovB2c = document.getElementById('roundbutton-b2c-mov')
  var toggleBtnUmovf = document.getElementById('roundbutton-umovf')
  var toggleSwitchB2b = document.getElementById('toggleswitch-b2b')
  var toggleSwitchLimit = document.getElementById('toggleswitch-limit')
  var toggleSwitchDirectPayments = document.getElementById('toggleswitch-direct-payments')
  var toggleSwitchNotes = document.getElementById('toggleswitch-notes')
  var toggleSwitchInvoicing = document.getElementById('toggleswitch-automated-invoicing')
  var toggleSwitchSendConfirmationCopy = document.getElementById('toggleswitch-send-b2b-oc-copy')
  var toggleSwitchMov = document.getElementById('toggleswitch-mov')
  var toggleSwitchMovB2c = document.getElementById('toggleswitch-b2c-mov')
  var toggleSwitchUmovf = document.getElementById('toggleswitch-umovf')

  if ((toggleBtnB2b) && (toggleSwitchB2b)) {
    toggleBtnB2b.addEventListener('click', () => {
      if (toggleSwitchB2b.checked) {
        toggleSwitchB2b.checked = false;
      }
      else {
        toggleSwitchB2b.checked = true;
      }
    });
  };

  if ((toggleBtnLimit) && (toggleSwitchLimit)) {
    toggleBtnLimit.addEventListener('click', () => {
      if (toggleSwitchLimit.checked) {
        toggleSwitchLimit.checked = false;
      }
      else {
        toggleSwitchLimit.checked = true;
      }
    });
  };

  if ((toggleBtnDirectPayments) && (toggleSwitchDirectPayments)) {
    toggleBtnDirectPayments.addEventListener('click', () => {
      if (toggleSwitchDirectPayments.checked) {
        toggleSwitchDirectPayments.checked = false;
      }
      else {
        toggleSwitchDirectPayments.checked = true;
      }
    });
  };

  if ((toggleBtnNotes) && (toggleSwitchNotes)) {
    toggleBtnNotes.addEventListener('click', () => {
      if (toggleSwitchNotes.checked) {
        toggleSwitchNotes.checked = false;
      }
      else {
        toggleSwitchNotes.checked = true;
      }
    });
  };

  if ((toggleBtnInvoicing) && (toggleSwitchInvoicing)) {
    toggleBtnInvoicing.addEventListener('click', () => {
      if (toggleSwitchInvoicing.checked) {
        toggleSwitchInvoicing.checked = false;
      }
      else {
        toggleSwitchInvoicing.checked = true;
      }
    });
  };

  if ((toggleBtnSendConfirmationCopy) && (toggleSwitchSendConfirmationCopy)) {
    toggleBtnSendConfirmationCopy.addEventListener('click', () => {
      if (toggleSwitchSendConfirmationCopy.checked) {
        toggleSwitchSendConfirmationCopy.checked = false;
      }
      else {
        toggleSwitchSendConfirmationCopy.checked = true;
      }
    });
  };

  if ((toggleBtnMov) && (toggleSwitchMov)) {
    toggleBtnMov.addEventListener('click', () => {
      if (toggleSwitchMov.checked) {
        toggleSwitchMov.checked = false;
      }
      else {
        toggleSwitchMov.checked = true;
      }
    });
  };

  if ((toggleBtnUmovf) && (toggleSwitchUmovf)) {
    toggleBtnUmovf.addEventListener('click', () => {
      if (toggleSwitchUmovf.checked) {
        toggleSwitchUmovf.checked = false;
      }
      else {
        toggleSwitchUmovf.checked = true;
      }
    });
  };

  if ((toggleBtnMovB2c) && (toggleSwitchMovB2c)) {
    toggleBtnMovB2c.addEventListener('click', () => {
      if (toggleSwitchMovB2c.checked) {
        toggleSwitchMovB2c.checked = false;
      }
      else {
        toggleSwitchMovB2c.checked = true;
      }
    });
  };
};

export const onOffToggleVat = () => {
  var toggleBtn = document.getElementById('roundbutton-vat')
  var toggleSwitch = document.getElementById('toggleswitch-vat')

  if ((toggleBtn) && (toggleSwitch)) {
    toggleBtn.addEventListener('click', () => {
      if (toggleSwitch.checked) {
        toggleSwitch.checked = false;
      }
      else {
        toggleSwitch.checked = true;
      }
    });
  };
};

export const onOffToggleDelivery = () => {
  var toggleBtn = document.getElementById('roundbutton-delivery')
  var toggleSwitch = document.getElementById('toggleswitch-delivery')
  var toggleBtnLimitDelivery = document.getElementById('roundbutton-limit-b2b-delivery')
  var toggleSwitchLimitDelivery = document.getElementById('toggleswitch-limit-b2b-delivery')

  if ((toggleBtn) && (toggleSwitch)) {
    toggleBtn.addEventListener('click', () => {
      if (toggleSwitch.checked) {
        toggleSwitch.checked = false;
      }
      else {
        toggleSwitch.checked = true;
      }
    });
  };

  if ((toggleBtnLimitDelivery) && (toggleSwitchLimitDelivery)) {
    toggleBtnLimitDelivery.addEventListener('click', () => {
      if (toggleSwitchLimitDelivery.checked) {
        toggleSwitchLimitDelivery.checked = false;
      }
      else {
        toggleSwitchLimitDelivery.checked = true;
      }
    });
  };
};

